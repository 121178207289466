
.spare-list{
    display: flex;
    flex-direction: column;
    height: 100%;
    .title{
        font-size: 20px;
        padding-left: 38px;
        line-height: 1;
        margin: 20px 0 30px;
        display: flex;
        align-items: center;
    }
    .content{
        padding: 0 70px;
        height: 100%;
        .item{
            margin-bottom:20px;
            .span-title{
                display: inline-block;
                text-align: right;
                width:70px;
            }
        }
    }

    .dialog-footer{
        margin: 20px 0;
        text-align: center;
    }
}

